jQuery(function($){

  _gnav($);
  _gnav_current($);
  _gnav_carousel($);
});

let _gnav = ($) => {

  var _type_click = () => {
    $('.is-subnav', '.global-nav').on('click', (sub) => {
      var _sub = $(sub.currentTarget).data('gnav');
      $(sub.currentTarget).siblings('[data-gnav]:not([data-gnav="'+ _sub +'"])').removeClass('showSub');
      $(sub.currentTarget).toggleClass('showSub');
      $('.overlay.showSub').remove();

      var _subnav = $('.subnav[data-gnav="'+ _sub +'"]');
      if ( $(sub.currentTarget).hasClass('showSub') ) {
        $('body > .subnav[data-gnav].showSub').remove();
        _subnav.clone(true,true).addClass('showSub').prependTo('body');

        $('.overlay.showSub').remove();
        $('body').prepend('<div class="overlay showSub"/>');

      } else {
        $('body > .subnav[data-gnav]').remove();
        $('.global-header').prop({'style':''});
      }
      var _subnavpos = function(){
        _gh_H = Math.floor( $('.global-header').outerHeight() ) || 0;
        _sc = Math.floor( $('body,html').scrollTop() );

        var orientation = window.orientation;
        if ( orientation === 0 ) {
          $('.subnav[data-gnav].showSub').css({'top': (_gh_H)+'px' });
        } else {
          $('.subnav[data-gnav].showSub').css({'top': (_gh_H - _sc)+'px' });
        }
      }
      window.addEventListener('scroll', _subnavpos);
      window.addEventListener('resize', _subnavpos);
      _subnavpos();

      $(document).on('click', function(closeCli) {
        if( !$(closeCli.target).closest($('.is-subnav', '.global-nav')).length ){
          $('.overlay.showSub').remove();
          $('body > .subnav[data-gnav]').remove();
          $(sub.currentTarget).removeClass('showSub');
          $('body').prop({'style':''});
        }
      });
    });
  }
  var _type_hover = () => {
    $('.is-subnav').on('mouseenter', (sub) => {
      var _sub = $(sub.currentTarget).data('gnav');
      $('body > .subnav[data-gnav]').remove();
      $('.overlay.showSub').remove();

      var _subnav = $('.subnav[data-gnav="'+ _sub +'"]');

      _subnav.clone(true,true).addClass('showSub').prependTo('body');
      $('body').prepend('<div class="overlay showSub" />');

      var _subnavpos = function(){
        _gh_H = Math.floor( $('.global-header').outerHeight() ) || 0;
        _sc = Math.floor( $('body,html').scrollTop() );
        $('.subnav[data-gnav].showSub').css({'top': (_gh_H - _sc)+'px' });
      }
      window.addEventListener('scroll', _subnavpos);
      window.addEventListener('resize', _subnavpos);
      _subnavpos();
    });

    $('[data-gnav]:not(".is-subnav")', '.global-nav').on('mouseenter', (sub) => {
      $('.overlay.showSub').remove();
      $('body > .subnav[data-gnav]').remove();
    });

    $('.global-header-inner').on('mouseenter', (sub) => {
      $('.overlay.showSub').remove();
      $('body > .subnav[data-gnav]').remove();
    });

    $('.subnav').on('mouseleave', (sub) => {
      $('.overlay.showSub').remove();
      $('body > .subnav[data-gnav]').remove();
    });
  }

  $('.is-subnav').removeClass('showSub');
  $('.overlay.showSub').remove();

  if ( $(window).innerWidth() < 1280) {
    _type_click();
  } else {
    _type_hover();
  }
}
let _gnav_current = ($) => {
  let _current_name = (typeof kbds_canonical_name !== 'undefined') ? kbds_canonical_name : '';

  $('.global-nav li[data-gnav]').each( (i, nav) => { // メニューのカレント表示
    var _current_nav =  $(nav).data('gnav');
    if( _current_name === _current_nav ) {
      $(nav).addClass('current');
    }
  });
}
let _gnav_carousel = ($) => {
  var _gnav = $('.global-nav ul');
  // var _iniNum = (_gnav.find('li.current').index()) ? _gnav.find('li.current').index() : 0;

  var _currentNum = _gnav.find('li.current').index();

  const iniNum = (_currentNum) => {
    if ( _currentNum < 4 && $(window).innerWidth() < 640 ) {
      return nnn = 0;
    } else if ( _currentNum < 8 && $(window).innerWidth() > 640 ) {
      return nnn = 3;
    } else {
      return nnn = _gnav.find('li.current').index();
    }
  }
  var _iniNum = iniNum();

  let _slideTarget = '.global-nav.splide';
  let _slideOptions = {
    type: 'slide',
    autoWidth: true,
    slideFocus: true,
    perMove: 3,
    dragMinThreshold: 5,
    easing: 'ease-out',
    arrows: true,
    pagination: false,
    drag: 'free',
    padding: '0.5rem',
    mediaQuery: 'min',
    focus: 'center',
    trimSpace: 'move',
    start: _iniNum,
    gap: '0.5rem',
    breakpoints: {
      834: {
        focus: '0',
      },
      1281: {
        arrows: false,
        gap: '0',
      },
    },
  }
  let _gnavSplide = new Splide(_slideTarget, _slideOptions);
  _gnavSplide.mount();
}
